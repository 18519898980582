let model = {};

// dữ liệu form
model.dataForm = {
    "id": undefined,
    "name": undefined,
    "province_ids": [],
    "net_xs": [],
    "roles": undefined
};

model.roles = [
    {
        value: "View",
        label: "View"
    },
    {
        value: "Edit",
        label: "Edit"
    }
]

model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: true,
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sort: "name",
    defaultSort: ["name", "ascending"],
    fields: "id,name,province_ids,net_xs,roles",
    filters: "",
    dataSearch: {
        value: {
            name: "",
            net_xs: "",
        },
        operator: {
            name: ":regex_i:",
            net_xs: ":in:"
        },
    },
};

export default model;
